@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?hz6xc');
  src:  url('fonts/icomoon.eot?hz6xc#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?hz6xc') format('truetype'),
    url('fonts/icomoon.woff?hz6xc') format('woff'),
    url('fonts/icomoon.svg?hz6xc#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-third-eye:before {
  content: "\e902";
}
.icon-egyptian-eye:before {
  content: "\e901";
}
.icon-puzzle-heart:before {
  content: "\e93e";
}
.icon-seed-of-life:before {
  content: "\e900";
  font-weight: 500;
}
.icon-mindfulness:before {
  content: "\e903";
  font-weight: 700;
}
